import { ref } from "vue";
import { defineStore } from "pinia";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { useRouter } from "vue-router";

export interface User {
  name: string;
  employee_id: string;
  email: string;
  password: string;
  api_token: string;
  permissions: string[];
}

type UserData = {
  token: string;
  id: number;
  employee_id: number;
  name: string;
  email: string;
  telegram_id: string;
  alerts_by: string;
  permissions: string[];
  activated: boolean;
};

export const useAuthStore = defineStore("auth", () => {
  const errors = ref({});
  const user = ref<UserData>({} as UserData);
  const isAuthenticated = ref(!!JwtService.getToken());
  const router = useRouter();

  function setAuth(userData: UserData) {
    isAuthenticated.value = true;
    user.value = structuredClone(userData);
    errors.value = {};
    JwtService.saveToken(user.value.token);
  }

  function setError(error: any) {
    errors.value = { ...error };
  }

  function purgeAuth() {
    isAuthenticated.value = false;
    user.value = {} as UserData;
    errors.value = [];
    JwtService.destroyToken();
  }

  function cannot(permission: string | null = null) {
    if (user.value.permissions && permission) {
      return !user.value.permissions.includes(permission);
    }
    return false;
  }

  function getUser(fn: any, redirect: boolean = true) {
    if (Object.keys(user.value).length === 0) {
      getUserData(fn, redirect);
      return;
    }
    fn(user.value);
  }

  function login(credentials: User) {
    return ApiService.post("/auth/login", credentials)
      .then(({ data }) => {
        if (data.data.activated == 0) {
          purgeAuth();
          router.push("/sign-in?deactivated=1")
          return;
        }
        setAuth(data.data);
      })
      .catch(({ response }) => {
        setError(response.data);
      });
  }

  function logout() {
    purgeAuth();
  }

  function setPermissions(fn) {
    ApiService.setHeader();
    ApiService.get("/getuserpermissions")
      .then(({ data }) => {
        user.value.permissions = data.data;
        fn(user.value);
      });
  }

  function register(credentials: User) {
    return ApiService.post("register", credentials)
      .then(({ data }) => {
        setAuth(data);
      })
      .catch(({ response }) => {
        setError(response.data.errors);
      });
  }

  function forgotPassword(email: string) {
    return ApiService.post("forgot_password", email)
      .then(() => {
        setError({});
      })
      .catch(({ response }) => {
        setError(response.data.errors);
      });
  }

  function getUserData(fn, redirect: boolean) {
    ApiService.setHeader();
    ApiService.get("/me")
      .then(({ data }) => {
        if (data.data.activated == 0) {
          purgeAuth();
          router.push("/sign-in?deactivated=1")
          return;
          // router.push({
          //   path: "/sign-in",
          //   state: {
          //     activated: false,
          //   },
          // })
        }
        user.value = data.data;
        if (!user.value.permissions) {
          user.value.permissions = [];
        }
        setPermissions(fn);
      })
      .catch(({ response }) => {
        fn(false);
        purgeAuth();
        setError(response.data.errors);
        if (redirect) router.push("/");
      });
  }

  function verifyAuth() {
    let output = true;
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.get("/me")
        .then(({ data }) => {
          if (data.data.activated == 0) {
            purgeAuth();
            router.push("/sign-in?deactivated=1")
            return;
          }
          user.value = data.data;
          if (!user.value.permissions) {
            user.value.permissions = [];
          }
          setPermissions();
        })
        .catch(({ response }) => {
          setError(response.data.errors);
          purgeAuth();
          output = false;
        });
    } else {
      purgeAuth();
      output = false;
    }
    return output;
  }

  return {
    errors,
    user,
    isAuthenticated,
    login,
    logout,
    register,
    forgotPassword,
    verifyAuth,
    cannot,
    getUser,
  };
});
