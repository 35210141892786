import { createI18n } from "vue-i18n";

const messages = {
  en: {
    dashboard: "Dashboard",
    layoutBuilder: "Layout builder",
    craft: "Crafted",
    pages: "Pages",
    profile: "Profile",
    profileOverview: "Overview",
    projects: "Projects",
    campaigns: "Campaigns",
    documents: "Documents",
    connections: "Connections",
    wizards: "Wizards",
    horizontal: "Horizontal",
    vertical: "Vertical",
    account: "Account",
    accountOverview: "Overview",
    settings: "Settings",
    authentication: "Authentication",
    basicFlow: "Basic Flow",
    signIn: "Sign-in",
    signUp: "Sign-up",
    passwordReset: "Password Reset",
    multiStepSignUp: "Multi-steps Sign up",
    error404: "Error 404",
    error500: "Error 500",
    apps: "Apps",
    chat: "Chat",
    privateChat: "Private Chat",
    groupChat: "Group Chat",
    drawerChat: "Drawer Chat",
    widgets: "Widgets",
    widgetsLists: "Lists",
    widgetsStatistics: "Statistics",
    widgetsCharts: "Charts",
    widgetsMixed: "Mixed",
    widgetsTables: "Tables",
    widgetsFeeds: "Feeds",
    changelog: "Changelog",
    docsAndComponents: "Docs & Components",
    megaMenu: "Mega Menu",
    exampleLink: "Example link",
    modals: "Modals",
    general: "General",
    inviteFriends: "Invite Friends",
    viewUsers: "View Users",
    upgradePlan: "Upgrade Plan",
    shareAndEarn: "Share & Earn",
    forms: "Forms",
    newTarget: "New Target",
    newCard: "New Card",
    newAddress: "New Address",
    createAPIKey: "Create API Key",
    twoFactorAuth: "Two Factor Auth",
    createApp: "Create App",
    createAccount: "Create Account",
    documentation: "Documentation",
    components: "Components",
    resources: "Resources",
    activity: "Activity",
    customers: "Customers",
    gettingStarted: "Getting Started",
    customersListing: "Customers Listing",
    customerDetails: "Customers Details",
    calendarApp: "Calendar",
    subscriptions: "Subscriptions",
    getStarted: "Getting Started",
    subscriptionList: "Subscription List",
    addSubscription: "Add Subscription",
    viewSubscription: "View Subscription",
  },
  pt: {
  dashboard: "Painel",
  layoutBuilder: "Construtor de Layout",
  craft: "Trabalho",
  pages: "Páginas",
  profile: "Perfil",
  profileOverview: "Visão Geral do Perfil",
  projects: "Projetos",
  campaigns: "Campanhas",
  documents: "Documentos",
  connections: "Conexões",
  wizards: "Assistentes",
  horizontal: "Horizontal",
  vertical: "Vertical",
  account: "Conta",
  accountOverview: "Visão Geral da Conta",
  settings: "Configurações",
  authentication: "Autenticação",
  basicFlow: "Fluxo Básico",
  signIn: "Entrar",
  signUp: "Inscrever-se",
  passwordReset: "Redefinir Senha",
  multiStepSignUp: "Inscrição Multi-Etapas",
  error404: "Erro 404",
  error500: "Erro 500",
  apps: "Aplicativos",
  chat: "Chat",
  privateChat: "Chat Privado",
  groupChat: "Chat em Grupo",
  drawerChat: "Chat Drawer",
  widgets: "Widgets",
  widgetsLists: "Listas",
  widgetsStatistics: "Estatísticas",
  widgetsCharts: "Gráficos",
  widgetsMixed: "Misturados",
  widgetsTables: "Tabelas",
  widgetsFeeds: "Feeds",
  changelog: "Registro de Mudanças",
  docsAndComponents: "Documentos & Componentes",
  megaMenu: "Mega Menu",
  exampleLink: "Link de Exemplo",
  modals: "Modais",
  general: "Geral",
  inviteFriends: "Convidar Amigos",
  viewUsers: "Ver Usuários",
  upgradePlan: "Atualizar Plano",
  shareAndEarn: "Compartilhar e Ganhar",
  forms: "Formulários",
  newTarget: "Nova Meta",
  newCard: "Novo Cartão",
  newAddress: "Novo Endereço",
  createAPIKey: "Criar Chave API",
  twoFactorAuth: "Autenticação Dois Fatores",
  createApp: "Criar Aplicativo",
  createAccount: "Criar Conta",
  activity: "Atividade",
  documentation: "Documentação",
  components: "Componentes",
  resources: "Recursos",
  customers: "Clientes",
  gettingStarted: "Começando",
  customersListing: "Listagem de Clientes",
  customerDetails: "Detalhes do Cliente",
  calendarApp: "Calendário",
  subscriptions: "Inscrições",
  getStarted: "Começar",
  subscriptionList: "Lista de Inscrições",
  addSubscription: "Adicionar Inscrição",
  viewSubscription: "Ver Inscrição"
  },
  es: {
    dashboard: "Tablero",
    layoutBuilder: "Constructor de maquetación",
    craft: "Elaborado",
    pages: "Paginas",
    profile: "Perfil",
    profileOverview: "Descripción general",
    projects: "Proyectos",
    campaigns: "Campañas",
    documents: "Documentos",
    connections: "Conexiones",
    wizards: "Magos",
    horizontal: "Horizontal",
    vertical: "Vertical",
    account: "Cuenta",
    accountOverview: "Descripción general",
    settings: "Ajustes",
    authentication: "Autenticación",
    basicFlow: "Flujo básico",
    signIn: "Registrarse",
    signUp: "Inscribirse",
    passwordReset: "Restablecimiento de contraseña",
    multiStepSignUp: "Regístrese Multi-Pasos",
    error404: "Error 404",
    error500: "Error 500",
    apps: "Aplicaciones",
    chat: "Chat",
    privateChat: "Chat privado",
    groupChat: "Grupo de chat",
    drawerChat: "Chat del cajón",
    widgets: "Widgets",
    widgetsLists: "Liza",
    widgetsStatistics: "Estadísticas",
    widgetsCharts: "Gráficos",
    widgetsMixed: "Mezclada",
    widgetsTables: "Mesas",
    widgetsFeeds: "Alimenta",
    changelog: "Registro de cambios",
    docsAndComponents: "Documentos & Componentes",
    megaMenu: "Mega menú",
    exampleLink: "Enlace de ejemplo",
    modals: "Modales",
    general: "General",
    inviteFriends: "Invitar A Amigos",
    viewUsers: "Ver Usuarios",
    upgradePlan: "Plan De Actualización",
    shareAndEarn: "Compartir Y Ganar",
    forms: "Formas",
    newTarget: "Nuevo Objetivo",
    newCard: "Nueva Tarjeta",
    newAddress: "Nueva Direccion",
    createAPIKey: "Crea Clave De Api",
    twoFactorAuth: "Dos Factores",
    createApp: "Crear Aplicacion",
    createAccount: "Crear Una Cuenta",
    activity: "Actividad",
    documentation: "Documentación",
    components: "Componentes",
    resources: "Recursos",
    customers: "Clientes",
    gettingStarted: "Empezando",
    customersListing: "Listado De Clientes",
    customerDetails: "Detalles De Los Clientes",
    calendarApp: "Calendario",
    subscriptions: "Suscripciones",
    getStarted: "Empezando",
    subscriptionList: "Lista De Suscripción",
    addSubscription: "Añadir Suscripción",
    viewSubscription: "Suscripción",
  },
};

const i18n = createI18n({
  legacy: false,
  locale: "pt",
  globalInjection: true,
  messages,
});

export default i18n;
